import React from "react";

const Loop = (Component) => {
    return (props) => {
        const {posts} = props;
        return posts.map(post => {
            return <Component key={post.slug} post={post} {...props}/>
        })
    }
}

export default Loop
