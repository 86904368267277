import React from "react";
import parse from "html-react-parser";

const CareerTeaser = ({ post }) => {
    const location = post.career_acfs.careerLocation ? parse(post.career_acfs.careerLocation) : null;
    const excerpt = post.excerpt ? parse(post.excerpt) : null;
    const main = post.career_acfs.mainTechnologies ? parse(post.career_acfs.mainTechnologies) : null;
    return (
    <article className="career-teaser">
        <h3 className="career-teaser__title h3">
          {parse(post.title)}
        </h3>
        <div className="row columns-8">
            <div className="career-teaser__location">{location && <p>{location}</p>}</div>
            <div className="career-teaser__excerpt color-dark-gray">
                {main}
                <strong>{post.career_acfs.salary}</strong>
            </div>
            <div className="career-teaser__link"><a title="See the position" className="btn-primary" href={post.uri}>See the position</a></div>
        </div>
    </article>
  )
}

export default CareerTeaser
