import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import CareerLoop from "../components/career/career-loop";
import {graphql} from "gatsby";
import Image from "gatsby-image";

export default function Careers({data}) {
    const posts = data.allWpCareer.nodes;
    const ourOfficeImage = data.ourOffice.childImageSharp.fluid ? data.ourOffice.childImageSharp.fluid : null;
    const positions = posts.map(a => a.title);

    return (
        <Layout hideNewsletter>
            <SEO
                title="Careers - Derave Software"
                description={`We are looking for: ${positions.join(', ')} in Lublin or remote - Derave Software`}
            />
            <article className="single-article page-content" style={{'paddingTop': '20px'}}>
                <section id="career-opportunities" className="career-opportunities">
                    <div className="career-opportunities__header">
                        <div className="container-fluid">
                            <div className="row columns-8"><h1
                                className="single-article__title career-opportunities__title">Open positions</h1>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-gray">
                        <div className="row columns-8">
                            <div className="career-opportunities__list">
                                <CareerLoop posts={posts}/>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="why-us" className="why-us">
                    <div className="row columns-12">
                        <div className="container-fluid">
                            <div className="row columns-8"><h1 className="single-article__title why-us__title">Why
                                us?</h1>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row columns-8">
                                <div className="why-us__columns">
                                    <div className="row columns-8">
                                        <div className="why-us__column">
                                            <h4>Experienced IT specialists</h4>
                                            <p className="color-dark-gray">We’re a young software house created by competent IT professionals</p>
                                        </div>
                                        <div className="why-us__column">
                                            <h4>Efficiency and innovation</h4>
                                            <p className="color-dark-gray">We combine proven technologies with fresh concepts</p>
                                        </div>
                                        <div className="why-us__column">
                                            <h4>Knowledge sharing</h4>
                                            <p className="color-dark-gray">We work in small teams, which enables frequent contact and feedback</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="our-office" className="our-office">
                    <div className="our-office__container">
                        {ourOfficeImage && <div className="our-office__img">
                            <Image
                                fluid={ourOfficeImage}
                                alt="Our office"
                                className="gatsby-image-full"
                            />
                        </div>}
                        <div className="container-fluid">
                            <div className="row columns-8">
                                <div className="our-office__content">
                                    <div className="our-office__text">
                                        <h1 className="single-article__title why-us__title">Our office</h1>
                                        <p>A pet-friendly place with a good vibe in the heart of Lublin –<br/> we even have a marten :)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </article>
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
  allWpCareer (
            sort: { fields: [date], order: ASC }
        )
        {
    nodes {
      career_acfs {
         careerLocation
         mainTechnologies
         salary
      }
      excerpt
      link
      uri
      title
      slug
    }
   }
  ourOffice: file(relativePath: { eq: "images/office2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`

